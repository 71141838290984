
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>会员行为偏好分析&预测体系</h3>
      <p>助力企业深度洞察消费者，赋能精细化运营</p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <div class="analysis">
        <img src="../../assets/images/analysis01.png" />
        <div>
          <h6>（一）构建会员分析模型</h6>
          <p>
            分析不同等级会员的消费品类偏好、不同等级会员的消费品牌偏好、不同等级会员的消费金额分布区间，根据不同会员的消费偏好，定向推送活动信息。
          </p>
        </div>
      </div>
      <div class="analysis">
        <img src="../../assets/images/analysis02.png" />
        <div>
          <h6>（二）会员预测体系</h6>
          <p>
            并对会员行为持续监测，建立成体系化的会员标签和预测体系，提供完善的会员解决方案。
          </p>
        </div>
      </div>
      <div class="analysis">
        <img src="../../assets/images/analysis03.png" />
        <div>
          <h6>（三）数据获取与整合</h6>
          <p>
            通过API、DB以及数据导入的方式，采集所有消费者与企业的多触点交易、商务行为数据，通过对数据进行清洗、重组，整合为One
            ID的数据。
          </p>
        </div>
      </div>
      <div class="analysis">
        <img src="../../assets/images/analysis04.png" />
        <div>
          <h6>（四）数据处理与洞察</h6>
          <p>
            多个维度定义各类属性的标签以及数据特征。将这些数据进行标签化、特征化，并运用多种模型对数据进行分析处理。基于个体消费者画像和群体行为统计数据，洞察群体行为背后的相关因素，并展示多维度洞察报告。
          </p>
        </div>
      </div>
    </div>
    <div class="demand">
      <h4 class="head">六大数据标签体系</h4>
      <p class="slogan">
        6大数据维度、160＋系统标签，更全面、细致丰富地描绘360°客户画像，助力企业针对不同的用户进行精准营销。
      </p>
      <img src="../../assets/images/analysis.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/Banner_4.png);
  background-size: 100% 100%;
  text-align: center;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    font-size: 0.2rem;
    line-height: 0.8rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  margin: 0.84rem 0;
  background-color: #fffff5;
  color: #333;
  padding: 1rem 1.6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .analysis {
    width: 7.72rem;
    height: 1.72rem;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0.36rem  0.3rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.42rem;
    img {
      width: 1rem;
      height: 1rem;
    }
    div {
      width: 5.94rem;
      h6 {
        font-size: 0.22rem;
        padding-bottom: 0.15rem;
      }
      p {
        font-size: 0.18rem;
        line-height: 0.27rem;
        color: #666;
      }
    }
  }
}
.demand {
  text-align: center;
  padding-bottom: 1rem;
  img {
    padding-top: 0.58rem;
    width: 14.88rem;
    height: 6.96rem;
  }
}
.head {
  font-size: 0.28rem;
  text-align: center;
  padding-bottom: 0.2rem;
}
.slogan {
  font-size: 0.18rem;
  color: #666;
  text-align: center;
}
</style>
